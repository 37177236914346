import React, { useEffect } from "react";

import lotte from "../../assets/lotte.jpg";

const Lotte = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Kutsu Lotte oma sünnipäevale</h2>
      <div className="row white-wrapper">
        <div className="col-lg-6 col-md-12 ">
          <p>
            <b>Leiutajateküla Lotte</b> on üks seiklushimuline koeratüdruk, kel
            on varuks alati palju mänge, mida nad Roosi, Bruno ja Albertiga
            kodus mängivad. Kui Peojuht Lotte sünnipäevale kutsuda, täitub tuba
            tantsu-tralliga. Laste lemmikud heeringas-heeringas-1-2-3 ja
            haned-luiged on mängud, mis ka arglikumad lapsed mängima kutsub.
          </p>
          <p>
            Piduliku koogi jaoks on Lottel kaasas ka ukulele, millega kõik koos
            sünnipäevalapsele pidulaulu laulda saavad. Kui lapsel või vanematel
            on erisoove, võib Lotte teha ka minikontserti, Lotte
            Olümpiavõistlust ja muid koeruseid.
          </p>
          <p className="pt-4 fw-bold">Kontaktandmed:</p>

          <p>
            Koduleht:{" "}
            <a
              className="black-link"
              href="https://www.lottega.ee"
              target="_blank"
            >
              https://www.lottega.ee
            </a>
          </p>
          <p className="pt-4 fw-bold">Hinnakiri:</p>
          <p>1h külastus 160€</p>
          <p>1.5h külastus 225€</p>
          <p>2h külastus 300€</p>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic"
            src={lotte}
            alt="Lotte hoiab silti ja naeratab"
          />
        </div>
      </div>
    </div>
  );
};

export default Lotte;
