import React from "react";
import { Link } from "react-router-dom";

const Assortment = () => {
  return (
    <div className="my-5">
      <h2 className="hero-h2">Sünnipäev - Filmiõhtu - Playdate</h2>
      <p>
        Sweetheart mängu- ja peostuudio on armas hubane kohake, mis on
        <br />
        südamega loodud Sinu kõige kallimate sündmuste tähistamiseks.
      </p>
      <ul>
        <li>Asume Mustamäe ja Kristiine piiril Tallinnas.</li>
        <li>
          Mängutuba võimaldab pidada lapse/laste sünnipäeva kuni 40 inimesega
        </li>
        <li>Korraldada noorte filmiõhtuid kuni 12-le</li>
        <li>Mänguhommikud beebidele</li>
      </ul>
      <Link to={"/tutvustus"}>
        <span className="black-link">Loe kõigest lähemalt</span>
      </Link>{" "}
      või{" "}
      <Link to={"/galerii"}>
        <span className="black-link"> vaata galeriid</span>
      </Link>
    </div>
  );
};

export default Assortment;
