import React from "react";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { FaParking } from "react-icons/fa";
import { GiClothes } from "react-icons/gi";
import { PiCoffeeFill } from "react-icons/pi";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="row home mb-5">
      <div className="col-lg-6 col-md-12 d-flex flex-column text-center d-md-block mt-4 mb-5 white-wrapper">
        <h1 className="h1">
          <span className="purple-text">Sweetheart</span> mängutuba Tallinnas
        </h1>
        <h3 className="hero-small-text pb-3">
          Lõbus ja meeldejääv lapse sünnipäev!
        </h3>

        <Link to="/broneerimine">
          <button className="btn btn-lg change-bg" type="button">
            Broneeri stuudio
          </button>
        </Link>
        <div className="row row-cols-2 g-3">
          <div className="col d-flex align-items-center">
            <FaParking size={"2rem"} title="parkimine" />
            <span className="mx-2">Tasuta parkimine</span>
          </div>
          <div className="col d-flex align-items-center">
            <CgSmartHomeRefrigerator size={"2rem"} title="külmkapp" />
            <span className="mx-2">Suur külmkapp tordile</span>
          </div>
          <div className="col d-flex align-items-center">
            <GiClothes size={"2rem"} title="konditsioneer" />
            <span className="mx-2">Kostüümid poistele ja tüdrukutele</span>
          </div>
          <div className="col d-flex align-items-center">
            <PiCoffeeFill size={"2rem"} title="kohv" />
            <span className="mx-2">Tasuta kohv</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
