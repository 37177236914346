import React, { useEffect } from "react";
import "../../style.css";
import NewOpportunities from "../NewOpportunities";
import Assortment from "../Assortment";
import Hero from "../Hero";
import Blog from "../Blog";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="">
      <div className="container d-flex flex-column align-items-center py-5">
        <Hero />
        <Assortment />
        <NewOpportunities />
        <Blog />
      </div>
    </div>
  );
};

export default Home;
