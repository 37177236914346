import React from "react";
import koogid from "../assets/blogImages/IMG_0165.jpeg";
import kuusk from "../assets/blogImages/IMG_9297.jpeg";
import { ImFacebook2 } from "react-icons/im";
import { GrInstagram } from "react-icons/gr";
import { TfiLayoutLineSolid } from "react-icons/tfi";

const Blog = () => {
  return (
    <div className="p-5">
      <h3>Mängutoa uudised</h3>
      <div className="row py-5">
        <h5>Sweetheart Stuudio, Kooker & Sõbrapäev - parim kooslus</h5>
        <p className="pb-3">21. Jaanuar 2025, Tallinn</p>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center pb-2">
          <div>
            <p>
              Mängutoa jaoks aasta kõige tähtsam tähtpäev on <b>sõbrapäev</b>.
              Oleme tänulikud kõigile oma suurtele ja väikestele sõpradele, kes
              meile külla tulevad ja oma vahvat pidu peavad.
            </p>
            <p>
              Mängutuba on kaunistatud terve veebruari kuu vastavalt. ❤️❤️❤️
            </p>
            <p>
              Sweetheart Stuudio korraldab ka Sõbrapäeva peo, millest saavad
              soovi korral osa võtta kõik meie külalised. Selleks jälgi meid
              sotsiaalmeedias:
            </p>
            <div className="d-flex justify-content center align-items-center pb-3 px-3">
              <a
                href="https://www.facebook.com/sweetheartstuudio"
                target="_blank"
                className="nav-link p-0 text-body-secondary"
              >
                <ImFacebook2 size={"2rem"} />
              </a>
              <a
                href="https://www.instagram.com/sweetheartstuudio"
                target="_blank"
                className="nav-link p-0 text-body-secondary px-3"
              >
                <GrInstagram size={"2rem"} />
              </a>
            </div>
            <p>
              Peo teeme magusaks maailma parimate Kookeri minipannkookidega.
            </p>
            <p>
              PS: Broneerides peo meie mängutoas saadame sulle ka Kookeri
              sooduskoodi,millega saate pannkoogid tellida <b>15% soodsamalt</b>
              !
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center pb-2">
          <img
            src={koogid}
            className="blog-img"
            alt="Kookeri pannkoogid sõbrapäeva kaunistudega"
          />
        </div>
        <div className="d-flex justify-content-center pt-5">
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
          <TfiLayoutLineSolid color={"999"} />
        </div>
      </div>

      <div className="row pt-5">
        <h5>Jõuluaeg meie mängutoas</h5>
        <p className="pb-3">14. Detsember 2024, Tallinn</p>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center pb-2">
          <div>
            <p>
              Meil on ettevalmistused tehtud ja <b>jõuluaeg</b> võib alata.
              Oleme ehtinud oma mängutoa jõulurüüse et, laste sünnipäevad sellel
              maagilisel ajal veel hubasemaks teha. 🎄
            </p>
            <p>
              Loomulikult olete oodatud ka oma jõulupidu meie juures pidama. Hea
              võimalus korraldada beebigruppide, lasteaiarühma või lihtsalt
              sõpruskonna jõuluistumist. 🎅🤶
            </p>
            <p>
              Ära unusta ka piiluda meie lisateenuseid. Oleks ju vahva kutsuda
              lumisele sünnipäeva peole Lumekuninganna Elsa ja lasta see armas
              pidu jäädvustada meie fotograaf Jaana poolt.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center pb-2">
          <img
            src={kuusk}
            className="blog-img"
            alt="Ehitud kuusk ja selle all kingid"
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
