import React, { useEffect } from "react";

const HouseRules = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="beige-wrapper">
      <div className="container py-5 ">
        <h2>Kodukord</h2>
        <div className="white-wrapper">
          <p className="pt-2">
            Broneeringutasu maksmisega kinnitab tellija (edaspidi
            Peokorraldaja), et on tutvunud Sweetheart mängu- ja peostuudio
            (edaspidi Stuudio) kodukorra reeglitega ja järgib neid.
          </p>
          <h4 className="pt-3">Heakord</h4>
          <ul>
            <li>
              <p>
                Stuudio Peokorraldaja peab kinni elementaarsetest puhtuse ja
                korra reeglitest.
              </p>
            </li>
            <li>
              <p>
                Peokorraldaja vastutab kogu ürituse vältel Stuudio ruumide
                heakorra eest. Stuudio varale kahju tekitamisel või hilisemal
                eripuhastuse vajaduse korral on Stuudiol õigus nõuda
                Peokorraldajalt kahju hüvitamist.
              </p>
            </li>
            <li>
              <p>
                Välisjalanõud ja üleriided tuleb jätta välisukse juurde selleks
                ettenähtud kohta.
              </p>
            </li>
            <li>
              <p>Siseruumides võib viibida vahetusjalanõudes või sokkides.</p>
            </li>
            <li>
              <p>
                Lapsevankrid, tõuke- ja jooksurattad palume jätta Stuudio ukse
                taha või I korrusele välisukse juurde.
              </p>
            </li>
            <li>
              <p>
                Stuudio ei vastuta Peokorraldaja ja tema külaliste riiete ja
                isiklike asjade eest.
              </p>
            </li>
            <li>
              <p>Koduloomi palume Stuudiosse mitte kaasa võtta.</p>
            </li>
            <li>
              <p>
                Söömine toimub vaid söögitoas, peolaua taga. Palume söökide ja
                jookidega mängualale mitte minna.
              </p>
            </li>
            <li>
              <p>
                Mängualale ei tohi kaasa võtta teravaid esemeid (söögiriistad,
                käärid jms).
              </p>
            </li>
            <li>
              <p>
                Stuudios olevaid mänguasju, lauanõusid ja muid aksessuaare
                palume kasutada heaperemehelikult ning nende purunemise/kaasa
                võtmise korral on stuudiol õigus nõuda Peokorraldajalt
                kahjutasu.
              </p>
            </li>
            <li>
              <p className="text-danger fw-bold">
                Vulkaanküünalde, säraküünalde ja muu pürotehnika kasutamine on
                Stuudios rangelt keelatud.
              </p>
            </li>
            <li>
              <p className="text-danger fw-bold">
                Suitsetamine ja alkohoolsete jookide tarbimine on Stuudios
                rangelt keelatud.
              </p>
            </li>
            <li>
              <p>
                Peokorraldaja ja lapsevanemad vastutavad kogu ürituse vältel
                laste järelevalve, turvalisuse ja ohutuse eest. Stuudio ei
                vastuta külaliste tervisekahjustuste ja vigastuste eest, mis on
                tekkinud Stuudio kodukorra reeglite eiramise tulemusena. Stuudio
                teeb omalt poolt kõik selleks, et külalistel oleks ohutu
                kasutada stuudio poolt pakutavaid atraktsioone.
              </p>
            </li>
            <li>
              <p>
                Palume järgida ning lastele selgitada Stuudio ohutusnõudeid ja
                reegleid.
              </p>
            </li>
            <li>
              <p>Stuudiosse ootame vaid terveid lapsi ja täiskasvanuid.</p>
            </li>
            <li>
              <p>
                Peokorraldaja võib saabuda 15 minutit enne peo algust Stuudiosse
                ettevalmistusi tegema, kui ei ole Stuudioga teistmoodi kokku
                lepitud.
              </p>
            </li>
            <li>
              <p>
                Pidu kestab 2,5 tundi, millest palume kinni pidada. Eelneval
                kokkuleppel saab juurde võtta lisa aega (iga lisa 30 minutit on
                30 eur). Kui kokkulepitud peo lõpu kellaajast minnakse üle,
                hakkavad jooksma lisaminutid (1 min = 2 eur).
              </p>
            </li>
            <li>
              <p>
                Ürituse lõppedes palume Stuudios teha söögilaud puhtaks, praht
                visata prügikasti ning kasutatud nõud asetada
                nõudepesumasinasse. Nõud, mis ei mahu nõudepesumasinasse tuleb
                puhtaks pesta, kuivatada ning asetada tagasi kappi. Mängualal
                palume asju mitte laiali jätta.
              </p>
            </li>
            <li>
              <p>
                Lisateenuste tellimises on võimalik eelnevalt kokku leppida
                e-maili või telefoni teel.
              </p>
            </li>
            <li>
              <p>
                Soovides meiepoolset köögi- ja mänguala koristusteenust (35
                eur), palume sellest teada anda Stuudiot broneerides.
                Koristusteenus sisaldab söögiala puhastamist, nõude pesemist ja
                mänguala koristust. Pallimere pallid palume ise tagasi panna.
                Enda toodud asjad (toit, nõud jms) koristab laualt
                Peokorraldaja.
              </p>
            </li>
            <li>
              <p>
                Kui peole on tellitud lisateenuse pakkuja (peojuht vms), siis
                antud teenuse pakkuja on kohustatud tagama puhtuse enda teenuse
                pakkumise järel. Kui teenusepakkuja on jätnud endast maha prahi
                vms olukorra, mis nõuab erikoristust, siis on teenusepakkuja
                kohustatud tasuma koristusteenuse. Erikoristuse hind on 50
                eurot.
              </p>
            </li>
            <li>
              <p>
                Palume Stuudios mitte kasutada serpentiine ja konfette, sest
                neid on väga keeruline saada kätte vaipkatetelt. Nende
                eemaldamine nõuab erikoristust, mille hind on 50 eurot.
              </p>
            </li>
            <li>
              <p>
                Kui mingid kodukorra reeglid jäävad arusaamatuks, siis on
                võimalus üle küsida töötaja käest (stuudio tel nr +372
                58892820). Kodukorra reeglite mittemõistmine ei vabasta nende
                täitmisest.
              </p>
            </li>
          </ul>
          <h4 className="pt-3">Inventar</h4>
          <ul>
            <li>
              <p>
                Meie köögis saab kasutada suurt külmikut, ahju, mikrolaineahju,
                kohvimasinat, veekeedukannu. Meie kappidest leiad tassid,
                klaasid, lusikad, noad, kahvlid, tordilabida, tordialused,
                morsikannud, serveerimisalused jms 35 inimesele.
              </p>
            </li>
            <li>
              <p>Meie poolt on ka kohv, tee, suhkur.</p>
            </li>
            <li>
              <p>
                Stuudio atraktsioonid on mõeldud eelkooliealistele lastele, kuid
                alla 3-aastaseid lapsi ilma täiskasvanu järelevalveta mängualale
                lubada ei tohi.
              </p>
            </li>
            <li>
              <p>
                Mängualal on väikeseid detaile ja osakesi, mis ei ole sobilikud
                alla 3-aastastele lastele.
              </p>
            </li>
            <li>
              <p>
                Palume veenduda, et lapsed ei näriks mängutoas närimiskummi.
              </p>
            </li>
            <li>
              <p>
                Mängutoas olevaid atraktsioone tohib kasutada vaid
                sihtotstarbeliselt.
              </p>
            </li>
            <li>
              <p>
                Mänguasjadega käitutakse heaperemehelikult ehk neid ei loobita,
                lõhuta ega võeta koju kaasa.
              </p>
            </li>
            <li>
              <p>
                Kui mõni mänguasi katki läheb (õnnetusi ikka juhtub), palume
                sellest kohe teavitada Stuudio töötajat.
              </p>
            </li>
            <li>
              <p>
                Juhul kui mõni ese kogemata koju kaasa tuleb, palume Stuudioga
                ühendust võtta ja see meile esimesel võimalusel tagasi tuua.
              </p>
            </li>
            <li>
              <p>
                Sõiduvahenditega tuleb liigelda ettevaatlikult, jalakäijatega
                arvestades. Üksteise rammimine ei ole lubatud. Mitmekesi auto
                peale minna ei ole lubatud.
              </p>
            </li>
            <li>
              <p>
                Nukuvankrid ja -kärud on mõeldud vaid nukkude sõidutamiseks.
                Lapsed nendele istuda ei tohi.
              </p>
            </li>
            <li>
              <p>
                Aknalaudadel, bussi ja mängumajakeste akendel, katustel, ustel,
                terrasside piiretel ning trepi käsipuudel palume mitte turnida.
              </p>
            </li>
            <li>
              <p>Liumäest üles ronimine ja nendel hüppamine on keelatud.</p>
            </li>
            <li>
              <p>
                Kriitidega joonistamine on mõeldud ainult selleks ettenähtud
                tahvelseinal.
              </p>
            </li>
            <li>
              <p>
                Heli- ja muu tehnikaga võivad opereerida ainult lapsevanemad või
                mängutoa töötajad.
              </p>
            </li>
          </ul>
          <h4 className="pt-3">Broneerimine</h4>
          <ul>
            <li>
              <p>
                Stuudio broneerimine toimub broneerimisankeedi täitmisega
                kodulehel www.sweetheartstuudio.ee, kirjutades e-mailile
                info@sweetheartstuudio.ee või telefoni teel +372 58892820.
              </p>
            </li>
            <li>
              <p>
                Pärast broneerimisankeedi saatmist saadab Stuudio
                Peokorraldajale broneeringukinnituse koos arvega. Kinnituskiri
                ei ole automaatvastusena, mistõttu võib vastus veidi aega võtta.
              </p>
            </li>
            <li>
              <p>
                Arvel on broneeringutasu 40 eur, mis ei kuulu tagastamisele, kui
                pidu jääb ära Stuudiost mitteolenevatel põhjustel.
              </p>
            </li>
            <li>
              <p>
                Pärast arve saamist palume tasuda broneerimistasu 24h jooksul
                meie arveldusarvele.
              </p>
            </li>
            <li>
              <p>Broneerimistasu arvestatakse sündmuse kogumaksumusest maha.</p>
            </li>
            <li>
              <p>
                Ülejäänud osa tasumine toimub arve alusel ülekandega hiljemalt
                sündmuse päevaks või sularahas enne sündmust.
              </p>
            </li>
            <li>
              <p>
                Broneeringu tühistamise korral vähem kui üks nädal enne üritust,
                tuleb tasuda kogu renditasu.
              </p>
            </li>
            <li>
              <p>
                Broneeringust loobumise korral palume sellest teada anda
                esimesel võimalusel (hiljemalt 24h enne planeeritud ürituse
                toimumist). Sellisel juhul on võimalik kokku leppida uus peo
                toimumise aeg, mida saab kasutada ühe aasta jooksul, vabade
                aegade olemasolul.
              </p>
            </li>
            <li>
              <p>Broneeringut saab muuta üks kord.</p>
            </li>
            <li>
              <p>
                Broneeringutasu tuleb maksta õigeaegselt, vastasel juhul
                vabastab Stuudio kuupäeva uutele soovijatele.
              </p>
            </li>
            <li>
              <p>Lisateenuste eest tasumine toimub kokkuleppel.</p>
            </li>
            <li>
              <p>
                Broneeritud ajal kohale mitteilmumise korral andke meile sellest
                kindlasti teada info@sweetheartstuudio.ee või nr +372 58892820.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HouseRules;
