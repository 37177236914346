import React, { useEffect } from "react";

import iceCream2 from "../../assets/photographerGallery/jaatis2.jpg";
import iceCream3 from "../../assets/photographerGallery/jaatis3.jpg";
import iceCream4 from "../../assets/photographerGallery/jaatis4.jpg";

const IceCream = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <div className="container py-5">
      <h2>Pehme jäätis</h2>
      <div className="row d-flex">
        <div className="col-lg-6 col-md-12 ">
          <p>Kes suudaks ei öelda suus sulavale pehmele jäätisele?</p>
          <p>Pakume võimalust rentida peoks pehmejäätisemasinat.</p>
          <p>
            Imemaitsev vanilje jäätis, mida saab enda meele järgi täiustada
            erinevate lisanditega.
          </p>
          <p>
            <li>
              Hind sisaldab ~4-5l pehmet vanilje jäätist, millest saab ~40
              portsjonit.
            </li>
            <li>40 vahvli- või papptopsi koos lusikatega</li>
            <li>Lisandid jäätisele</li>
          </p>
          <p>
            <b>Hind: 65€</b>
          </p>
          <p>
            Palume soovist teada anda broneeringut tehes, kirjutades{" "}
            <b>info@sweetheartstuudio.ee</b> või helistades <b>+372 58892820</b>
            .
          </p>
        </div>

        <div className="col-lg-6 col-md-12 ">
          <img
            className="max-100 intr-pic mb-4"
            src={iceCream4}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
      <div className="row ">
        <div className=" col-lg-6 col-md-12">
          <img
            className="max-100 intr-pic mb-4 "
            src={iceCream2}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <img
            className="max-100 intr-pic mb-4"
            src={iceCream3}
            alt="pannkoogid, moos ja lilled vaasis"
          />
        </div>
      </div>
    </div>
  );
};

export default IceCream;
